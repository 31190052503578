<template>
  <div class="upload-page bg-light">
    <div class="upload-page-content">
      <div class="upload-up">
        <el-upload
          drag
          action=""
          accept=".jpg,.png,.jpeg"
          :show-file-list="false"
          :http-request="customUploadPicture"
          :before-upload="handleBeforeUpload"
        >
          <div
            class="icon-box"
            :class="{ 'icon-box-img': ruleForm.resource_path }"
          >
            <el-image
              class="img"
              v-if="ruleForm.resource_path"
              :src="ruleForm.resource_path"
              :fit="'cover'"
            >
            </el-image>
            <img
              v-if="!ruleForm.resource_path"
              class="icon-upload"
              src="../../assets/images/upload-icon.png"
              alt=""
            />
          </div>
          <div class="red" v-if="loading">图片上传中，请稍后～</div>
          <div class="el-upload__texts" v-if="!ruleForm.resource_path">
            选择图片
          </div>
          <div class="el-upload__texts" v-if="!ruleForm.resource_path">
            或将图片拖放于此处
          </div>
          <div class="el-upload__btn" v-if="ruleForm.resource_path">
            重新上传
          </div>
          <div
            class="el-upload__tips"
            :class="{ 'el-upload__tips_ssp': ruleForm.resource_path }"
          >
            <p class="el-upload__tips_format">
              仅限单张，图片大小不超过20MB，格式为JPEG/PNG
            </p>
            <p>
              中国国家地理·频道为原创展示平台，请上传本人享有著作权的作品。
            </p>
            <!-- <p>禁止上传他人作品，如有侵权。。。。。。</p> -->
          </div>
        </el-upload>
      </div>
      <div class="upload-center">
        <div class="upload-form">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="115px"
            class="ruleForm"
          >
            <el-form-item prop="title" label-width="0px">
              <span class="label-up">作品名称</span>
              <el-input
                class="input-must"
                style="width: 485px;margin-bottom:70px;height: 54px;line-height:54px;display:block;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                v-model="ruleForm.title"
                placeholder="请输入作品名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="description" label-width="0px">
              <span class="label-up">作品简介</span>
              <el-input
                style="margin-bottom:70px;"
                type="textarea"
                resize="none"
                v-model="ruleForm.description"
                placeholder="每一个作品背后有专属于它自己的故事"
                maxlength="200"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-row style="margin-bottom:52px;">
              <el-col :span="15">
                <el-form-item label="拍摄时间" prop="shooting_time">
                  <el-date-picker
                    type="date"
                    placeholder="年-月-日"
                    v-model="ruleForm.shooting_time"
                    value-format="yyyy-MM-dd"
                    style="width: 253px;height: 54px;line-height:54px;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                  ></el-date-picker> </el-form-item
              ></el-col>
              <el-col :span="9"
                ><el-form-item label="拍摄设备" prop="shooping_equipment">
                  <el-input
                    style="width: 253px;height: 54px;line-height:54px;background: #EEEEEE;border: 0px solid #10070B;border-radius: 7px;"
                    v-model="ruleForm.shooping_equipment"
                    placeholder="请输入拍摄设备"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row style="margin-bottom:56px;">
              <el-col :span="15">
                <el-form-item label="拍摄地点" prop="shooting_address">
                  <el-input
                    style="width: 423px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    v-model="ruleForm.shooting_address"
                    placeholder="请输入拍摄地址"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="属性" prop="attribute">
                  <el-select
                    class="input-must"
                    style="width: 109px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.attribute"
                    placeholder="请选择作品属性"
                  >
                    <el-option label="公开" :value="1"></el-option>
                    <el-option label="私密" :value="2"></el-option>
                  </el-select> </el-form-item
              ></el-col>
            </el-row>
            <el-row style="margin-bottom:60px;">
              <el-col :span="15">
                <el-form-item label="拍摄方式" prop="shooting_model">
                  <el-select
                    class="input-must"
                    style="width: 127px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.shooting_model"
                    placeholder="-请选择-"
                  >
                    <el-option
                      v-for="item in shootingWayArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="拍摄速度" prop="shooping_speed">
                  <el-select
                    style="width: 127px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;"
                    :popper-append-to-body="false"
                    v-model="ruleForm.shooping_speed"
                    placeholder="-请选择-"
                  >
                    <el-option
                      v-for="item in shootingSpeedArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item prop="keywords" label-width="0px">
              <div class="tips-box">
                <span class="label-up">关键词</span>
                <img
                  style="width:14px;height:14px;margin-left:22px;"
                  src="@/assets/images/keywords-icon.png"
                  alt=""
                />
                <div class="tips">{{ count }}/30个关键词</div>
              </div>
              <!-- <el-input
                type="textarea"
                maxlength="100"
                resize="none"
                placeholder="请输入关键词，多个以、隔开"
                show-word-limit
                v-model="ruleForm.keywords"
                style="margin-bottom:16px;"
              ></el-input> -->
              <tags-input v-model="tags"> </tags-input>
            </el-form-item>
            <el-form-item label="分类" prop="category_name">
              <el-select
                class="input-must"
                popper-class="roleSelect"
                style="width: 109px;line-height:54px;height: 54px;background: #EEEEEE;border-radius: 7px;margin-bottom:40px;"
                :popper-append-to-body="false"
                v-model="ruleForm.category_name"
                placeholder="未分类"
              >
                <el-option
                  v-for="item in classifyArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="水印" prop="watermark">
              <el-radio-group v-model="ruleForm.watermark">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="2">否</el-radio>
              </el-radio-group>
              <span class="water-mark-tips radio-must"
                >【为作品添加中国国家地理·频道水印】</span
              >
            </el-form-item>
            <!-- <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >确认上传</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item> -->
          </el-form>
        </div>
      </div>
      <div class="upload-down">
        <div class="submit" @click="submitForm('ruleForm')">确认上传</div>
      </div>
    </div>
  </div>
</template>

<script>
import TagsInput from "@/components/tagsInput/tagsInput";
import { fileType } from "@/utils/utils";
// 要求上传的文件后缀
const fileSuffix = ["jpg", "png", "jpeg"];
export default {
  name: "uploadProductionImg",
  components: {
    TagsInput
  },
  data() {
    return {
      loading: false,
      shootingWayArr: [
        { label: "地面", value: 0 },
        { label: "航拍", value: 1 },
        { label: "水下", value: 2 },
        { label: "微距", value: 3 }
      ],
      shootingSpeedArr: [
        { label: "正常", value: 0 },
        { label: "延时", value: 1 },
        { label: "慢动作", value: 2 }
      ],
      classifyArr: [
        { label: "动物", value: 0 },
        { label: "植物", value: 1 },
        { label: "自然", value: 2 },
        { label: "人文", value: 3 },
        { label: "城市", value: 4 },
        { label: "水下", value: 5 },
        { label: "微距", value: 6 },
        { label: "航拍", value: 7 },
        { label: "创意", value: 8 }
        // { label: "横图", value: 9 },
        // { label: "竖图", value: 10 }
      ],
      tags: [], //关键词
      ruleForm: {
        user_id: localStorage.getItem("user_id"), //用户id
        resource_from: 1, //1前台添加2后台添加
        resource_type: 1, //1图片 2 视频
        resource_path: null, // 地址
        water_resource_path: null, //水印地址
        yasou_resource_path: null, //压缩地址
        title: "",
        attribute: 1,
        shooping_equipment: "",
        shooting_time: "",
        shooting_address: "", //拍摄地点
        shooting_model: "",
        shooping_speed: "",
        category_name: "",
        keywords: "",
        watermark: 1,
        description: ""
      },
      rules: {
        // title: [
        //   { required: true, message: "请输入作品名称", trigger: "blur" },
        //   { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        // ]
        // shooping_equipment: [
        //   { required: true, message: "请输入拍摄设备", trigger: "blur" }
        // ],
        // shooting_model: [
        //   { required: true, message: "-请选择-", trigger: "change" }
        // ],
        // shooping_speed: [
        //   { required: true, message: "-请选择-", trigger: "change" }
        // ],
        // category_name: [
        //   { required: true, message: "-请选择-", trigger: "change" }
        // ],
        // attribute: [
        //   { required: true, message: "请选择属性", trigger: "change" }
        // ],
        // shooting_address: [
        //   { required: true, message: "请输入拍摄地点", trigger: "blur" }
        // ]
        // shooting_time: [
        //   {
        //     type: "string",
        //     required: true,
        //     message: "请选择日期",
        //     trigger: "change"
        //   }
        // ],
        // watermark: [
        //   { required: true, message: "请选择是否添加水印", trigger: "change" }
        // ],
        // description: [
        //   { required: true, message: "请添加描述", trigger: "blur" }
        // ],
        // keywords: [
        //   { required: true, message: "请输入关键词", trigger: "blur" }
        // ],
        // resource_path: [
        //   { required: true },
        //   {
        //     validator: (rule, value, callback) =>
        //       this.checkUploadImg(rule, value, callback)
        //   }
        // ]
      }
    };
  },
  created() {},
  computed: {
    //count 关键词当前已有数量
    count() {
      return this.tags.length;
    }
  },
  async mounted() {},
  methods: {
    submitForm(formName) {
      let that = this;
      console.log(this.ruleForm.keywords);
      this.ruleForm.keywords = this.tags.join("、");
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 调用上传作品接口
          this.$api.postUpProduction(this.ruleForm).then(res => {
            if (res.data.code === 1) {
              console.log(res);
              this.$message({
                message: res.data.msg,
                type: "success"
              });
              setTimeout(() => {
                that.$router.push({
                  name: "personalCenter",
                  query: { PageUserId: localStorage.getItem("user_id") }
                });
              }, 3000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传文件
    handleBeforeUpload(file) {
      const type = fileType(file.name);
      if (!fileSuffix.includes(type)) {
        this.$message({
          message: "仅支持20MB，JPEG/PNG格式图片",
          type: "error",
          customClass: "error_self",
          center: true
        });
        return false;
      }
      if (file.size > 20 * 1024 * 1024) {
        // this.$message.error("仅支持20MB，JPEG/PNG格式图片");
        this.$message({
          message: "仅支持20MB，JPEG/PNG格式图片",
          type: "error",
          customClass: "error_self",
          center: true
        });
        return false;
      }
      console.log("图片信息", file);
      // return false;
    },
    customUploadPicture(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.loading = true;
      this.$api.postUpImages(formData).then(res => {
        if (res.data.code === 1) {
          console.log(res);
          this.ruleForm.resource_path = res.data.data.yuantu;
          this.ruleForm.yasou_resource_path = res.data.data.yasuo_url;
          this.ruleForm.water_resource_path = res.data.data.water_url;
          this.$refs.ruleForm.validateField("resource_path", valid => {
            if (!valid) return false;
          });
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      });
    },
    /**
     * 检查是否上传资源
     */
    checkUploadImg(rule, value, callback) {
      if (!value) {
        callback(new Error("请上传资源"));
        return;
      }
      callback();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message({
          message: "上传头像图片只能是 JPG 格式!",
          type: "error",
          center: true
        });
        // this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message({
          message: "上传头像图片大小不能超过 2MB!",
          type: "error",
          center: true
        });
        // this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.roleSelect {
  height: 416px;
  .el-scrollbar {
    height: 100%;
    .el-select-dropdown__wrap {
      max-height: 420px;
    }
  }
}
.error_self {
  top: 110px !important;
  background: #e60021 !important;
}

.error_self .el-message__content {
  color: #ffffff !important;
  font-family: PingFang SC !important;
  font-weight: 400 !important;
}
.upload-page {
  color: #000000;
  text-align: center;
  .upload-page-content {
    width: 1312px;
    margin: 0 auto;
    padding-top: 54px;
  }
  .upload-up {
    width: 100%;
    height: 645px;
    box-shadow: 0px 26px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    margin-bottom: 47px;
    text-align: center;
    .el-upload {
      width: 100%;
      height: 645px;
      .el-upload-dragger {
        background-color: #fff;
        border-radius: 6px;
        box-sizing: border-box;
        border: none;
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
        overflow: hidden;
        .icon-box {
          height: 191px;
          width: 191px;
          margin: 146px auto 54px;
          img,
          video {
            height: 100%;
            width: 100%;
          }
        }
        .icon-box-img {
          height: 372px;
          width: 1000px;
          margin: 54px auto 54px;
          .img {
            height: 100%;
            width: 100%;
          }
        }
        .red {
          position: relative;
          top: -20px;
          color: red;
        }
        .el-upload__texts {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #6a6a6a;
          line-height: 40px;
        }
        .el-upload__btn {
          width: 97px;
          height: 23px;
          background: #e60021;
          border-radius: 12px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
          display: inline-block;
        }
      }
    }
    .el-upload__tips {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #9d9d9d;
      line-height: 18px;
      background: #fff;
      padding-top: 68px;
      .el-upload__tips_format {
        margin-bottom: 18px;
      }
    }
    .el-upload__tips_ssp {
      padding-top: 25px;
    }
  }
  .upload-center {
    width: 100%;
    height: 1247px;
    background: #ffffff;
    box-shadow: 0px 26px 17px 0px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    text-align: center;
    .upload-form {
      padding: 105px 166px 75px;
      .el-form {
        .el-form-item {
          text-align: left;
          margin-bottom: 0;
          .el-form-item__label {
            text-align: left;
            font-size: 17px;
            height: 54px;
            line-height: 54px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #e6767b;
            background: #e6767b;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #e6767b;
          }
          .el-radio__label {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #000000;
          }

          .el-radio__inner {
            width: 21px;
            height: 21px;
          }
          .el-textarea__inner {
            width: 100%;
            display: block;
            height: 143px;
            font-size: 17px;
            font-family: PingFangSC-Light;
            font-weight: 300;
            color: #000000;
            background: #eeeeee;
            border: 0px solid #10070b;
            border-radius: 7px;
            &::placeholder {
              color: #787878;
            }
            &::-webkit-input-placeholder {
              /* WebKit browsers 适配谷歌 */
              color: #787878;
            }
            &:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 适配火狐 */
              color: #787878;
            }
            &::-moz-placeholder {
              /* Mozilla Firefox 19+ 适配火狐 */
              color: #787878;
            }
            &:-ms-input-placeholder {
              /* Internet Explorer 10+  适配ie*/
              color: #787878;
            }
          }
          .input-must {
            position: relative;
          }
          .input-must:after {
            font-family: monospace;
            position: absolute;
            top: 2px;
            right: -32px;
            content: "*";
            font-size: 22px;
            color: #f56c6c;
          }
          .radio-must {
            position: relative;
          }
          .radio-must:after {
            font-family: monospace;
            position: absolute;
            right: -20px;
            top: -6px;
            content: "*";
            font-size: 22px;
            color: #f56c6c;
          }
          .label-up {
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .water-mark-tips {
            padding-left: 60px;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #787878;
          }
          .avatar-uploader .el-upload {
            width: 219px;
            height: 123px;
            border-radius: 7px;
            background: #eeeeee;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }
          // .avatar-uploader .avatar {
          //   width: 219px;
          //   height: 123px;
          //   border-radius: 7px;
          // }
          .avatar-uploader .el-upload:hover {
            border-color: #409eff;
          }
          .icon-upload {
            margin-top: 28px;
            width: 67px;
            height: 67px;
          }
          .avatar-uploader .avatar {
            width: 100%;
            // height: 178px;
            display: block;
          }
        }
      }
      .el-input__inner {
        border: none;
        background: #eeeeee;
        border-radius: 7px;
        font-size: 17px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #000000;
        &::placeholder {
          color: #787878;
        }
        &::-webkit-input-placeholder {
          /* WebKit browsers 适配谷歌 */
          color: #787878;
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 适配火狐 */
          color: #787878;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ 适配火狐 */
          color: #787878;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10+  适配ie*/
          color: #787878;
        }
      }
      .el-textarea__inner {
        background: #eeeeee;
      }
      .el-input__count {
        background: #eeeeee;
      }
      .tips-box {
        position: relative;
        .tips {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #787878;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }
  .upload-down {
    width: 100%;
    padding: 62px 0;
    text-align: center;
    .submit {
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      width: 322px;
      text-align: center;
      border-radius: 30px;
      background-color: #ba001b;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 媒体查询 小于等于某个宽度
@media screen and (max-width: 1339px) {
  .upload-page {
    .upload-page-content {
      width: 1200px;
    }
  }
}
@media screen and (min-width: 1340px) and (max-width: 1921px) {
  .upload-page {
    .upload-page-content {
      width: 1312px;
    }
  }
}
@media screen and (min-width: 1921px) {
  .upload-page {
    .upload-page-content {
      width: 1312px;
    }
  }
}
</style>
<style lang="scss" scoped>
//重置select样式
/* 修改dropdown里面的背景颜色和边框颜色 */
::v-deep .el-select-dropdown {
  width: 109px;
  background-color: #acacac !important;
}

/* 修改dropdown里面的字体颜色 */
.el-select-dropdown__item {
  font-size: 17px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding: 0;
  margin: 0 26px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  &:last-child {
    border-bottom: none;
  }
}
/* 修改选择option：hover的颜色 */
.el-select-dropdown__item:hover {
  background-color: none;
}
/* 修改选择option后的颜色 */
.el-select-dropdown__item.selected {
  background-color: #acacac;
}
/* 修改滑入滑出背景样式 */
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #acacac;
}
/* 修改最上面小三角颜色 */
::v-deep .popper__arrow {
  display: none !important;
}
.el-select-dropdown
  .el-select-dropdown__wrap
  .el-select-dropdown__item.selected {
  color: #ffffff;
}
.roleSelect {
  .el-scrollbar__wrap--hidden-default::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f7fa;
  }
  ::-webkit-scrollbar-corner {
    background: #f5f7fa;
  }
}
</style>
